import React from "react"
import ReactDOM from "react-dom"
import styled from "@emotion/styled"


const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 3;
`

const Wrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Button = styled.button`
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: relative;
  padding: 3rem 0;
  background: none;
  span {
    &::before,
    &::after {
      content: "";
      height: 2px;
      width: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: #fafafa;
    }
    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
`

const Body = styled.span`
  z-index: 4;
`

const Modal = ({ isShowing, hide, children }) =>
  isShowing
    ? ReactDOM.createPortal(
        <>
          <Overlay>
            <Wrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
              <Header>
                <Button data-dismiss="modal" aria-label="Close" onClick={hide}>
                  <span aria-hidden="true" />
                </Button>
              </Header>
              <Body>{children}</Body>
            </Wrapper>
          </Overlay>
        </>,
        document.body
      )
    : null

export default Modal
