import React from "react"
import styled from "@emotion/styled"

import ModalBody from "./ModalBody"
import useModal from "../hooks/useModal"
import Loading from "./Loading"

const Container = styled.span`
  position: relative;
`

const LoadingWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Modal = ({ data, loaded, setLoaded, children }) => {
  const { isShowing, toggle } = useModal()
  function toggleLoaded() {
    setLoaded(false)
  }
  return (
    <Container onClick={toggle}>
      {children}
      <ModalBody isShowing={isShowing} hide={toggle && toggleLoaded}>
        {loaded ? null : (
          <LoadingWrapper>
            <Loading size="8" />
          </LoadingWrapper>
        )}
        {data}
      </ModalBody>
    </Container>
  )
}

export default Modal
