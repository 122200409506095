import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Breadcrumbs from "../components/Breadcrumbs"
import MarkdownBody from "../components/MarkdownBody"
import Articles from "../components/Articles"
import OnTv from "../components/OnTv"

export const MediaPageTemplate = ({
  title,
  subtitle,
  articles,
  html,
  breadcrumbs,
}) => {
  return (
    <>
      <Header title={title} subtitle={subtitle}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 35">
          <path d="M720.135 83.64c-16.53 21.328-142.258 45.84-213.076 62.784-70.664 16.872-86.574 26.106-104.874 41.287-18.147 15.109-39.147 36.236-83.451 54.242-44.613 18.006-112.527 33.033-153.47 23.877-41.098-9.227-54.916-42.496-55.87-69.788-1.109-27.221 10.647-48.324-27.386-83.722-38.187-35.327-126.163-84.949-106.616-99 19.394-13.981 146.62 7.607 233.387 9.572C295.702 24.927 342.475 7.198 392.823 4.45c50.35-2.607 106.47 49.31 172.87 14.61 141.804-74.104 170.973 43.394 154.442 64.58z" />
        </svg>
      </Header>
      <Breadcrumbs data={breadcrumbs} />
      <MarkdownBody data={html} />
      <Articles data={articles} />
      <OnTv />
    </>
  )
}

const MediaPage = ({
  data: {
    markdownRemark: {
      frontmatter: { title, subtitle, articles },
      html,
    },
  },
  location,
}) => {
  const breadcrumbs = location.pathname.split("/")
  const seoDesc = `ReCollect picks up your cans and electronics, and direct deposits you the refund. ${title}`
  return (
    <Layout
      title={`${title} | ReCollect | Book A Pickup Today`}
      seoDesc={seoDesc}
    >
      <MediaPageTemplate
        title={title}
        subtitle={subtitle}
        articles={articles}
        html={html}
        breadcrumbs={breadcrumbs}
      />
    </Layout>
  )
}

export default MediaPage

export const PageQuery = graphql`
  query mediaQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "media-page" } }) {
      frontmatter {
        title
        subtitle
        articles {
          title
          subtitle
          data {
            title
            author
            url
          }
        }
      }
      html
    }
  }
`
